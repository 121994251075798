@media (max-width: 834px) {
  .formDiv {
    width: 70%;
    padding: 30px;
  }
  .form input,
  .form textarea,
  .form select {
    font-size: 18px;
  }

  .sendBtn {
    font-size: 18px;
  }
  .dataConsentText {
    font-size: 16px;
  }
  /* Navigation */
  .nav {
    flex: 6;
    font-size: 1.2em;
    text-align: right;
  }
  /* Mentions légales */
  .legalMentionDiv {
    padding: 20px;
  }
  /* Carousel */
  .carousel-text {
    font-size: 3em;
    top: 15%;
    right: 2%;
    width: 80%;
  }
  .introDiv {
    width: 90%;
  }
  .homeDetailsDiv {
    width: 90%;
  }
  .homeServicesDiv {
    flex-direction: column;
    gap: 20px;
    padding: 10px;
  }
  .indoorDiv {
    width: 70%;
  }
}
