@font-face {
  font-family: 'Roboto Slab';
  src: url('./fonts/Gotham\ Book.otf') format('truetype');
  font-weight: 100 900;
  font-style: normal;
}

/* Repeat for each static font weight if needed */
@font-face {
  font-family: 'Roboto Slab';
  src: url('./fonts/Gotham\ Book.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto Slab';
  src: url('./fonts/Gotham\ Book.otf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto Slab';
  src: url('./fonts/Gotham\ Book.otf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto Slab';
  src: url('./fonts/Gotham\ Book.otf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  margin: 0px;
  width: 100%;

  margin: auto;
  font-family: 'Roboto Slab', sans-serif;
}
body,
#root {
  margin: 0;
  width: 100%;
  font-family: 'Roboto Slab', sans-serif;
}

.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
h1,
h2 {
  color: #5ab13a;
  font-family: 'Roboto Slab', sans-serif;
  padding-left: 20px;
  font-weight: 600;
  margin-top: 10px;
}
h3 {
  color: white;
}
a {
  text-decoration: none;
  color: black;
}
.homeTitle {
  color: #5ab13a;
}
/* General Styles */
/* .homeTitle,
.indoorTitle,
.outdoorTitle {
  display: none;
} */
.header {
  width: 100%;
}

.phoneAndMailDiv {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-color: #5ab13a;
  color: #fff;
  text-align: center;
  padding: 10px;
}
.phoneAndMailDiv a {
  color: #fff;
  text-decoration: none;
}

.desktopMailPhone,
.mobileMailPhone {
  margin: 0;
  padding: 0;
}

.navAndLogoDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #030405;
  padding: 20px;
}

.bannerLogoDiv {
  flex: 1;
  text-align: left;
}

.bannerLogo {
  max-width: 100%;
  height: auto;
  max-height: 150px;
}

.nav {
  flex: 6;
  font-size: 1.6em;
  text-align: right;
}

.desktopNavList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.desktopNavList a {
  text-decoration: none;
  color: #5ab13a;
}

.desktopNavList a:hover {
  text-decoration: underline;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #030405;
  margin-top: 30px;
}
.logoFooter {
  height: 140px;
}

.footer a {
  color: #5ab13a;
  text-decoration: none;
  font-size: 1em;
}
.footer a:hover {
  text-decoration: underline;
}

/* Mentions Légales */

.legalMentionDiv {
  padding: 20px 40px;
  background-color: #fff;
  color: #000;
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
}

.legalMentionDiv h1,
.legalMentionDiv h2 {
  color: #5ab13a;
  margin-bottom: 10px;
}

.legalMentionDiv p {
  margin-bottom: 20px;
  line-height: 1.5;
  font-size: 1em;
}
/* CAROUSEL */

@keyframes moveOutUp {
  0% {
    transform: translateY(0) scale(1);
  }
  100% {
    transform: translateY(-100%) scale(0.8);
  }
}

@keyframes moveOutDown {
  0% {
    transform: translateY(0) scale(1);
  }
  100% {
    transform: translateY(100%) scale(0.8);
  }
}

@keyframes moveInUp {
  0% {
    transform: translateY(100%) scale(0.8);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}

@keyframes moveInDown {
  0% {
    transform: translateY(-100%) scale(0.8);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}

.carousel-image.moving-out-up,
.carousel-text.moving-out-up {
  animation: moveOutUp 1s forwards;
}

.carousel-image.moving-out-down,
.carousel-text.moving-out-down {
  animation: moveOutDown 1s forwards;
}

.carousel-image.moving-in-up,
.carousel-text.moving-in-up {
  animation: moveInUp 1s forwards;
}

.carousel-image.moving-in-down,
.carousel-text.moving-in-up {
  animation: moveInDown 1s forwards;
}

.carousel-container {
  height: 800px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.carousel-slide {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center bottom;
}

.carousel-text {
  position: absolute;
  top: 30%;
  right: 10%;
  z-index: 3;
  color: #fff;
  width: 45%;
  border-radius: 5px;
  transition: transform 1s;
  font-size: 3.5em;
  pointer-events: none;
}

.carousel-image {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 1s;
  object-fit: cover;
  object-position: center;
}
.carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  pointer-events: none;
}
.carouselBtnDiv {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 2rem;
  padding: 0 10px;
  z-index: 2; /* ensures the buttons are above the images */
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  height: fit-content;
}
.carouselBtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 4rem;
  color: white;
  padding: 0 10px;
}

.study-steps-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.steps-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.step {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 1s;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;

  box-sizing: border-box;
  width: 70%; /* Take full width of the parent container */
  border: 2px solid #5ab13a; /* Use brand color for border */
  border-radius: 10px; /* Increase border-radius for a softer look */
  margin-bottom: 10px; /* Add some margin for spacing between steps */
  text-align: left; /* Align text to the left */
}

.stepTitle2 {
  width: 80%;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
}
.step p {
  width: 80%;
}
.step:hover {
  background-color: #f5f5f5;
}

.step.active {
  background-color: #e5e5e5;
  width: 100%;
}

.connector {
  height: 2px;
  width: 20px;
  background-color: #ccc;
  position: relative;
}
.study-steps-container {
  max-width: 1200px; /* Add a max-width for better readability on large screens */
  margin: 0 auto; /* Center the container */
  padding: 20px; /* Add some padding */
}

.steps-wrapper {
  flex-direction: column; /* Stack steps vertically */
  width: 100%; /* Take full width of the parent container */
}



.step h2 {
  color: #5ab13a; /* Use brand color for titles */
  margin: 0; /* Remove margin to reduce space */
}

.step.active {
  background-color: #5ab13a; /* Use brand color for active step */
  color: #fff; /* Change text color to white for better contrast */
}

.step.active h2 {
  color: #fff; /* Change title color to white for better contrast */
}

.connector {
  height: 2px;
  width: 100%; /* Extend the connector to full width */
  background-color: #5ab13a; /* Use brand color for connectors */
  margin: 10px 0; /* Add some margin for spacing */
}

.collapseContent {
  transition: max-height 0.5s ease, opacity 0.3s ease;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.collapseContent.active {
  max-height: 1000px;
  opacity: 1;
}

.mobileNav {
  display: none;
}
.mobileMailPhone {
  display: none;
}

.formDiv {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 50px auto;
  padding: 40px;
  width: 50%;
  box-sizing: border-box;
}

.formTitle {
  margin-bottom: 20px;
  text-align: center;
}
.form h2 {
  margin-bottom: 15px;
}
.form {
  display: flex;
  flex-direction: column;
}

.form input,
.form textarea {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 15px;
}
.addImgGrid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
}
.addedImgItemDiv {
  width: 25%;
  min-width: 150px;
  display: flex;
  flex-direction: column;

  height: 150px;
  justify-content: space-between;
  overflow: hidden;
}
.addedImgItem {
  height: 70%;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  word-break: break-word;
}
.addedPdfIcon {
  height: 50%;
}

.deleteImgButton {
  height: 20%;
  background-color: transparent;
  box-shadow: none;
  border-radius: 5px;
}
.form textarea {
  margin-top: 10px;
  resize: none;
  min-height: 100px;
}

.formElementDiv {
  color: black;
  font-weight: 600;
  margin-bottom: 30px;
}
.dropzone {
  border-style: solid;
  border-color: #5ab13a;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form select {
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
}
.consentBox {
  display: flex;
  align-items: center;
  margin-top: 15px;
  padding: 0px;
}
.dataConsent {
  flex: 1;
  height: 20px;
  margin: 0px;
}

.dataConsentText {
  font-size: 14px;
  color: #666;
  margin-left: 10px;
  flex: 6;
  margin: 0px;
}
.legalInfo {
  margin-top: 15px;
  text-align: center;
  font-size: 12px;
}
.sendBtn {
  background-color: #5ab13a;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  text-transform: uppercase;
  transition: background-color 0.3s ease-in-out;
}

.sendBtn:hover {
  background-color: #43912c;
}

.messageSent {
  text-align: center;
  padding: 20px;
  font-size: 18px;
}

.linkBackHome {
  color: #5ab13a;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.linkBackHome:hover {
  color: #43912c;
}

/* HOMEPAGE CONTENT  */
.homeContentDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.introDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  gap: 15px;
  padding: 15px;
  text-align: center;
}
.homeLogo {
  height: 200px;
}
.homeDetailsDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 60%;
  text-align: center;
  padding: 10px;
}
.homeServicesDiv {
  display: flex;
  gap: 20px;
  padding: 10px;
}
.homeDetailsSubDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  text-align: center;
  gap: 15px;
}
.homeText {
  text-align: center;
}
.closingArgumentText {
  font-size: 1.5em;
}

/* indoor/outdoor */

.indoorSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.indoorDiv {
  padding: 15px;
  align-self: center;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: center;
  align-items: center;
  border-style: solid;
  border-color: #5ab13a;
  background-color: #030405;
  margin-top: 25px;
}
.indoorText {
  color: white;
  width: 75%;
}
