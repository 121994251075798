@media (max-width: 480px) {
  .mobileNav {
    display: block;
  }
  .nav {
    display: none;
  }

  .collapseTitle {
    height: 35px;
    width: 35px;
    font-size: 1.5em;
    position: fixed;
    top: 45px;
    right: 0px;
    z-index: 5;
    background: black;
    border: none;
    border-radius: 5px;
    color: #5ab13a;
  }
  .collapseContent {
    z-index: 4;
    height: 95vh;
    width: 100vw;
    position: fixed;
    left: 0;
    padding-left: 25px;
    top: 5vh;
    background-color: #030405;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
  }
  .bannerLogoDiv {
    margin-top: 55px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bannerLogo {
    height: 150px;
  }
  .mobileNavList {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    list-style-type: none;
    height: 100%;
    font-size: 2.5em;
  }
  .collapseContent a {
    color: #5ab13a;
  }
  .desktopMailPhone {
    display: none;
  }
  .mobileMailPhone {
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .phoneAndMailDiv {
    position: fixed;
    width: 100%;
    justify-content: center;
    height: 5vh;
    min-height: 45px;
    z-index: 5;
  }
  .formDiv {
    width: 90%;
    padding: 20px;
  }
  .form input,
  .form textarea,
  .form select {
    font-size: 20px;
  }
  .sendBtn {
    font-size: 20px;
    width: 100%;
  }
  .dataConsentText {
    font-size: 18px;
  }
  /* Carousel */
  .carousel-text {
    font-size: 2em;
    top: 15%;
    right: 10%;
    width: 80%;
  }
  .indoorDiv {
    width: 95%;
  }
  .homeServicesDiv {
    width: 100%;
  }
  .homeText {
    text-align: center;
  }
  .closingArgumentText {
    font-size: 1.2em;
  }
}
